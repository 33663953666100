import { OpenAI } from 'openai';

class AlgorithmStage {
    type: string;
    ai: OpenAI;
    words: (studentSaid: string, teacherAnswered: string, studentResponded: string) => Promise<string>;
    thought: (studentSaid: string, teacherAnswered: string, studentResponded: string) => Promise<string>;
    next: AlgorithmStage[];

    constructor(type: string, ai: OpenAI, 
        words: (studentSaid: string, teacherAnswered: string, studentResponded: string) => Promise<string>, 
        thought: (studentSaid: string, teacherAnswered: string, studentResponded: string) => Promise<string>, next: AlgorithmStage[]) {
        this.type = type;
        this.ai = ai;
        this.words = words;
        this.thought = thought;
        this.next = next;
    }
}

export { AlgorithmStage };