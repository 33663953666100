import { AlgorithmStage } from './AlgorithmStage';
import { Algorithm } from './Algorithm';
import { OpenAI } from 'openai';

class TeachingAlgorithm extends Algorithm {
    constructor(t: any, openai: OpenAI, questions: any[]) {
        super();
        let question1: string = questions.length > 0 ? questions[0] : t('SOME TASK FOR SKILL TRAINING (THE MENTOR SHOULD KNOW)');
        let question2: string = questions.length > 1 ? questions[1] : question1;
        const repeatCommand = "Repeat after me: ";
        // Initialize all stages
        const giveInsurance = new AlgorithmStage(
            'success',
            openai,
            // Words to say
            async (studentSaid: string, teacherAnswered: string, studentResponded: string) => {
                return "Great! You've mastered this skill.";
            },
            // Thought
            async (studentSaid: string, teacherAnswered: string, studentResponded: string) => {
                return "";
            },
            []
        );

        const askStudentToRepeatTheAnswer = new AlgorithmStage(
            'askToRepeatAfterMe',
            openai,
            // Words to say
            async (studentSaid: string, teacherAnswered: string, studentResponded: string) => {
                const request = `Provide a succinct answer to: "${studentSaid}".`;
                const chatCompletion = await openai.chat.completions.create({
                    messages: [{ role: 'user', content: request }],
                    model: 'gpt-4-1106-preview',
                });
                const response = chatCompletion.choices[0].message.content;
                const result = response ? response : "";
                return repeatCommand+result;
            },
            // Thought
            async (studentSaid: string, teacherAnswered: string, studentResponded: string) => {
                const withoutRepeatPhrase = teacherAnswered.replace(repeatCommand, "");
                const request = `Has phrase: "${studentResponded}" the same meaning as: "${withoutRepeatPhrase}"? Answer just "1" if true, "0" otherwise.`;
                const chatCompletion = await openai.chat.completions.create({
                    messages: [{ role: 'user', content: request }],
                    model: 'gpt-4-1106-preview',
                });
                const response = chatCompletion.choices[0].message.content;
                console.log("request", request)
                console.log("response", response)
                return response? response : "";
            },
            []
        );

        const provideFakeAnswer = new AlgorithmStage(
            'fakeanswer',
            openai,
            // Words to say
            async (studentSaid: string, teacherAnswered: string, studentResponded: string) => {
                const request = `Just provide a wrong answer to: "${studentResponded}". Don't provide any comments that it's intentionally incorrect.`;
                const chatCompletion = await openai.chat.completions.create({
                    messages: [{ role: 'user', content: request }],
                    model: 'gpt-4-1106-preview',
                });
                const response = chatCompletion.choices[0].message.content;
                const result = response ? (response.endsWith('.') ? response.slice(0, -1) : response) : "";
                return result + ". Correct me if I am wrong.";
            },
            // Thought
            async (studentSaid: string, teacherAnswered: string, studentResponded: string) => {
                const request = `Is answer: "${studentResponded}" is correct for the task "${studentSaid}"? Answer just "1" if true, "0" otherwise.`
                const chatCompletion = await openai.chat.completions.create({
                    messages: [{ role: 'user', content: request }],
                    model: 'gpt-4-1106-preview',
                });
                const response = chatCompletion.choices[0].message.content;
                console.log("request", request)
                console.log("response", response)
                return response? response : "";
            },
            [askStudentToRepeatTheAnswer, giveInsurance]//, repeatNextDay]
        );

        const askToRepeatTaskAfterMeTheTask = new AlgorithmStage(
            'askToRepeatAfterMe',
            openai,
            // Words to say
            async (studentSaid: string, teacherAnswered: string, studentResponded: string) => {
                return repeatCommand + question1;
            },
            // Thought
            async (studentSaid: string, teacherAnswered: string, studentResponded: string) => {
                const withoutRepeatPhrase = teacherAnswered.replace(repeatCommand, "");
                const request = `Has phrase: "${studentResponded}" the same meaning as: "${withoutRepeatPhrase}"? Answer just "1" if true, "0" otherwise.`;
                const chatCompletion = await openai.chat.completions.create({
                    messages: [{ role: 'user', content: request }],
                    model: 'gpt-4-1106-preview',
                });
                const response = chatCompletion.choices[0].message.content;
                console.log("request", request)
                console.log("response", response)
                return response? response : "";
            },
            []
        );

        // Link stages
        this.begin = new AlgorithmStage(
            'begin',
            openai,
            // Words to say
            async (studentSaid: string, teacherAnswered: string, studentResponded: string) => {
                return 'Create an exercise similar to: ' + question1;
            },
            // Thought
            async (studentSaid: string, teacherAnswered: string, studentResponded: string) => {
                const request = `Does the exercise "${studentResponded}" train the same skill as exercise "${question1}" or "${question2}"? Answer just "1" if true, "0" otherwise.`
                const chatCompletion = await openai.chat.completions.create({
                    messages: [{ role: 'user', content: request }],
                    model: 'gpt-4-1106-preview',
                });
                const response = chatCompletion.choices[0].message.content;
                return response? response : "";
            },
            [askToRepeatTaskAfterMeTheTask, provideFakeAnswer]
        );

        askStudentToRepeatTheAnswer.next = [askStudentToRepeatTheAnswer, this.begin];
        askToRepeatTaskAfterMeTheTask.next = [askToRepeatTaskAfterMeTheTask, this.begin];

    }
}

export { TeachingAlgorithm };