import React, { useState, useEffect } from 'react';
import { OpenAI } from 'openai';
import { TeachingAlgorithm } from './TeachingAlgorithm';
import { AlgorithmStage } from './AlgorithmStage';
import { Container, TextField, Button, Typography, Paper } from '@mui/material';

const t = (s: string): string => {
    return s;
}

const GPT3Chat: React.FC = () => {
    const [openAI, setOpenAI] = useState<OpenAI | null>(null);
    const [currentStage, setCurrentStage] = useState<AlgorithmStage | null>(null);
    const [exampleTask1, setExampleTask1] = useState<string>('What is a result of 2+3?');
    const [exampleTask2, setExampleTask2] = useState<string>('What is a result of 4+5?');
    const [studentSaid, setStudentSaid] = useState<string>("");
    const [teacherAnswered, setTeacherAnswered] = useState<string>("");
    const [studentResponded, setStudentResponded] = useState<string>("");
    const [wereTheStudentTasksAndAnswersPerfectToday, setWereTheStudentTasksAndAnswersPerfectToday] = useState(true);
    const [chatHistory, setChatHistory] = useState<Array<{ sender: string, message: string }>>([]);
    const [isEditing, setIsEditing] = useState<boolean>(false);

    const onExampleTaskEdit = () => {
        setIsEditing(true);
    };
    const onSaveExampleTasks = () => {
        setChatHistory([]);
        setStudentSaid("");
        setTeacherAnswered("");
        setStudentResponded("");
        setIsEditing(false);
        // Additional logic if needed when tasks are saved
    };

    const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            processStudentResponse();
        }
    };    

    useEffect(() => {
        async function initAI() {
            const apiKey = process.env.REACT_APP_OPENAI_API_KEY;
            if (!apiKey) {
                console.error('OpenAI API key is not defined');
                return;
            }
            const openai = new OpenAI({
                apiKey: apiKey,
                dangerouslyAllowBrowser: true
            });
            setOpenAI(openai);
        }
        initAI();
    }, []);

    useEffect(() => {
        if (openAI) {
            const algo = new TeachingAlgorithm((s: string) => s, openAI, [exampleTask1, exampleTask2]);
            setCurrentStage(algo.getBegin());
        }
    }, [openAI, exampleTask1, exampleTask2, isEditing]);


    useEffect(() => {
        async function teacherResponse() {
            const words = await currentStage?.words(studentSaid, teacherAnswered, studentResponded);
            // Update chat history with AI response
            const aiAnswer = words ? words : "";
            setTeacherAnswered(aiAnswer);
            if(aiAnswer){
                setChatHistory([...chatHistory, { sender: "AI", message: aiAnswer }]);
            }
            setStudentSaid(studentResponded);
            setStudentResponded("");
        }
        teacherResponse();
        console.log("currentStage", currentStage)
    }, [currentStage]);

    const onStudentWordsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setStudentResponded(event.target.value);
    };

    const onExampleTask1Change = (event: React.ChangeEvent<HTMLInputElement>) => {
        setExampleTask1(event.target.value);
    };
    
    const onExampleTask2Change = (event: React.ChangeEvent<HTMLInputElement>) => {
        setExampleTask2(event.target.value);
    };

    const processStudentResponse = async () => {
        setChatHistory([...chatHistory, { sender: "Me", message: studentResponded }]);
        try {
            if (currentStage) {
                const nextStages = currentStage.next;
                const response = await currentStage.thought(studentSaid, teacherAnswered, studentResponded);
                if (response === '1') {
                    if (currentStage.type === 'fakeanswer' && !wereTheStudentTasksAndAnswersPerfectToday) {
                        setCurrentStage(nextStages[2]);
                    } else {
                        setCurrentStage(nextStages[1]);
                    }
                } else if (response === '0') {
                    if (currentStage.type !== 'askToRepeatAfterMe') {
                        setCurrentStage(nextStages[0]);
                    } else{
                        setChatHistory([...chatHistory, { sender: "Me", message: studentResponded }, { sender: "AI", message: teacherAnswered }]);
                    }
                }
            }
        } catch (error) {
            console.error('Error querying OpenAI:', error);
        }
    };

    return (
        <Container maxWidth="sm">
            <Paper style={{ padding: '20px', marginTop: '20px' }}>
                <Typography variant="h5">Exercises to learn how to solve</Typography>
                <TextField
                    fullWidth
                    label="Example Exercise 1"
                    margin="normal"
                    value={exampleTask1}
                    onChange={onExampleTask1Change}
                    disabled={!isEditing}
                />
                <TextField
                    fullWidth
                    label="Example Exercise 2"
                    margin="normal"
                    value={exampleTask2}
                    onChange={onExampleTask2Change}
                    disabled={!isEditing}
                />
                {isEditing ? (
                    <Button variant="contained" color="primary" onClick={onSaveExampleTasks}>
                        Save Tasks
                    </Button>
                ) : (
                    <Button variant="contained" color="secondary" onClick={onExampleTaskEdit}>
                        Edit Tasks
                    </Button>
                )}
            </Paper>
            {!isEditing && (
                <Paper style={{ padding: '20px', marginTop: '20px' }}>
                    <Typography variant="h5">Chat with Slonig AI</Typography>
                    <div style={{ marginBottom: '20px' }}>
                        {chatHistory.map((entry, index) => (
                            <Typography key={index}><strong>{entry.sender}:</strong> {entry.message}</Typography>
                        ))}
                    </div>
                    <TextField
                        fullWidth
                        label="Your Response"
                        margin="normal"
                        value={studentResponded}
                        onChange={onStudentWordsChange}
                        onKeyPress={handleKeyPress}
                    />
                    <Button variant="contained" color="primary" onClick={processStudentResponse}>
                        Respond
                    </Button>
                </Paper>
            )}
        </Container>
    );
};

export default GPT3Chat;